import { QueryClient } from "react-query";
import { createStandaloneToast, useToast } from "@chakra-ui/react";

function QueryErrorHandler(error) {
  const toast = useToast({
    isClosable: true,
    variant: "subtle",
    position: "bottom",
  });

  // error is type unknown because in js, anything can be an error (e.g. throw(5))
  const title =
    error instanceof Error ? error.message : "error connecting to server";

  // prevent duplicate toasts
  // toast.closeAll();
  toast({ title, status: "error", variant: "subtle", isClosable: true });
}

// to satisfy typescript until this file has uncommented contents
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: QueryErrorHandler,
    },
  },
});
