import React, { useState, useEffect } from "react";

export default function Refound() {
  return (
    <div>
      <div className="flex items-center justify-center min-h-[50rem]">
        <div className=""></div>
      </div>
    </div>
  );
}
